import { UrlBase } from './base-service'
import { Session } from '../js/session'

export class Service {

  constructor() {


    this.addressList = []
    this.resourceList = []
    this.resultReservation = false
    this.ctoAvailability = {}
    this.ontServiceTeste = {}
    this.activation = false
    this.suspension = false
    this.resume = false
  }


  
  //##############################################################################################################
  // FUNCTION: LOGIN
  // Details: 
  //##############################################################################################################
  static EfetuarLogin = async (email, senha) => {

    var request = {
      Email: email,
      Senha: senha
    }

    //GET
    await fetch(encodeURI(UrlBase + "authlayer/v1/session/login"), {
      mode: 'cors',
      credentials: 'omit',
     
      //headers: myHeadersExp,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoId': 'teste'
      },
      
      method: "POST",
      //body: JSON.stringify(reservationRequest._body),
      body: JSON.stringify(request),
    })
      .then((response) => {
        //return response.json();
      
        if (response.status == 200) {
          return response.json();
        }
        else {
          return response.status
        }
      })
      .then((data) => {
        
        this.usuario = data
      })

    return this.usuario;
  }

  //##############################################################################################################
  // FUNCTION: LOGIN
  // Details: 
  //##############################################################################################################
  static Session = async () => {

    //GET
    await fetch(encodeURI(UrlBase + "authlayer/v1/login/verify-session"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        
        this.usuario = data
      })

    return this.usuario;
  }

  //##############################################################################################################
  // FUNCTION: LOCATION BY ADDRESS
  // Details: 
  //##############################################################################################################
  static GetLocationByAddress = async (cep, numero) => {

    var request = {
      PostCode: cep,
      StreetNrFirst: numero
    }

    
    //GET
    await fetch(encodeURI(UrlBase + "authlayer/v1/locationByAddress?address"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(request),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log('result')
        console.log(result)
        if (result.data.responseStatus == 404 || result.data.responseStatus == 500) {
          this.addressList = result.data.responseStatus
        }
        else {
          this.addressList = result.data
        }
    
        //return 950
      })

    return this.addressList;
  }

  //##############################################################################################################
  // FUNCTION: DELETE RESOURCE
  // Details: Função qwue apaga o recurso
  //##############################################################################################################
  static DeleteResourceReservation = async (id) => {

    // const myHeadersExp = new Headers();
    // myHeadersExp.append('Authorization', 'Basic c2t5VXNlcjoqb3NUNjQyIyNJYg==');

    //DELETE
    await fetch(encodeURI(UrlBase + "authlayer/v1/resourceReservation/delete/" + id), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error('Something went wrong: DeleteResourceReservation');

      })

    return this.resultReservation
  }

  //##############################################################################################################
  // FUNCTION: RESERVATION
  // Details: Função qwue efetua a reserva
  //##############################################################################################################
  static ReserveResource = async (serviceId, speed, addressId, ctoId) => {

    var reservationRequest = {
      ServiceId: serviceId,
      Speed: speed,
      AddressId: addressId,
      CtoId: ctoId,
    }


    await fetch(encodeURI(UrlBase + "authlayer/v1/resourceReservation"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoID': Session.VnoId
      },
      //headers: myHeadersExp,
      method: "POST",
      //body: JSON.stringify(reservationRequest._body),
      body: JSON.stringify(reservationRequest),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log('data')
        console.log(data)
        this.resultReservation = data
      })

    return this.resultReservation

  }

  //##############################################################################################################
  // FUNCTION: AVAILABILTY 
  // Details: Função qwue verifica a viabilidade do endereço passado
  //##############################################################################################################
  static Availability = async (addressId) => {

    await fetch(encodeURI(UrlBase + "authlayer/v1/availability/" + addressId), {
      mode: 'cors',
      credentials: 'omit',
      //headers: myHeadersExp,
      method: "POST",
      //body: JSON.stringify(reservationRequest._body),
    })
      .then((response) => {
        return response.json();
      })
      .then((respsonse) => {
        
        this.ctoAvailability = respsonse.data
      })

    return this.ctoAvailability;
  }

  //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static GetResourcesByStatus = async (status) => {

    //GET
    //"https://authlayerapi.azurewebsites.net
    await fetch(encodeURI(UrlBase + "authlayer/v1/resourceReservation/serviceid/status/" + status), {
      mode: 'cors',
      credentials: 'omit',
      //   headers: myHeadersExp,
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data)
        this.resourceList = data
      })

    return this.resourceList;
  }

    //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static GeResourceGeneral = async (serviceId, ativacao, datainicio, datafim, downstream) => {

    
    var sidRequest = {
      ServiceId: serviceId,
      Ativacao: ativacao,
      Datainicio: datainicio,
      Datafim: datafim,
      Downstream: downstream,
    }

    await fetch(encodeURI(UrlBase + "authlayer/v1/resourceReservation/serviceid/general"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'VnoID': Session.VnoId
      },
      method: 'POST',
      body: JSON.stringify(sidRequest),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data)
        this.resourceList = data
      })

    return this.resourceList;
  }



  //##############################################################################################################
  // FUNCTION: ACTIVATION 
  // Details: Função que ativa o usuário na rede
  //##############################################################################################################
  static ResourceActivation = async (serviceId, serialNumber) => {

    var request = {
      ServiceId: serviceId,
      SerialNumber: serialNumber
    }

    console.log(JSON.stringify(request))

    await fetch(encodeURI(UrlBase + "authlayer/v1/activation"), {
      //await fetch(encodeURI(new URL(baseUrl) + "activation/serviceid"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(request),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.activation = data
      })

    return this.activation;
  }

  //##############################################################################################################
  // FUNCTION: SUSPENSION 
  // Details: Função qwue suspende o usuário na rede
  //##############################################################################################################
  static ResourceSuspension = async (id) => {

    var request = {
      serviceState: "SUSPEND_SERVICE",
    }

    await fetch(encodeURI(UrlBase + "authlayer/v1/service/" + id), {
      //await fetch(encodeURI(new URL(baseUrl) + "activation/serviceid"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH'
      },
      method: "PATCH",
      body: JSON.stringify(request),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.suspension = data
      })

    return this.suspension;
  }

  //##############################################################################################################
  // FUNCTION: Função que reativa o usuário na rede
  // Details: 
  //##############################################################################################################
  static ResourceResume = async (id, serialNumber) => {

    var request = {
      serviceState: "RESUME_SERVICE",
    }

    await fetch(encodeURI(UrlBase + "authlayer/v1/service/" + id), {
      //await fetch(encodeURI(new URL(baseUrl) + "activation/serviceid"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH'
      },
      method: "PATCH",
      body: JSON.stringify(request),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.resume = data
      })

    return this.resume;
  }

  //##############################################################################################################
  // FUNCTION: Função que faz o GET do Service Test na Gerência
  // Details: 
  //##############################################################################################################
  static GetOntServiceTest = async (id) => {

    //GET
    //"https://authlayerapi.azurewebsites.net
    await fetch(encodeURI(UrlBase + "authlayer/v1/service/test/" + id), {
      mode: 'cors',
      credentials: 'omit',
      //   headers: myHeadersExp,
      method: 'GET'
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.ontServiceTeste = data
      })

    return this.ontServiceTeste;
  }

  //##############################################################################################################
  // FUNCTION: Função que faz o GET do Service Test na Gerência
  // Details: 
  //##############################################################################################################
  static GetCustomers = async (id) => {

    //GET"
    //"https://authlayerapi.azurewebsites.net
    //id = "TESTE-123456-014";
    await fetch(encodeURI(UrlBase + "authlayer/v1/resourceReservation/phisicalPath/" + id), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH'
      },
      method: 'GET'
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.customers = data
      })

    return this.customers;
  }

  //##############################################################################################################
  // FUNCTION: ACTIVATION 
  // Details: Função que ativa o usuário na rede
  //##############################################################################################################
  static AddPoint = async (params) => {

    var retorno;

    var pointRequest = {
      CtoName: params.ctoName,
      Latitude: params.lat.toString(),
      Longitude: params.lng.toString(),
      Cep: params.cep.replace("-", ""),
      Numero: params.numero,
      Bairro: params.bairro,
      Logradouro: params.logradouro,
      Cidade: params.cidade,
      Estado: params.estado
    }

    await fetch(encodeURI(UrlBase + "v1/infra/add-point"), {
      //await fetch(encodeURI(new URL(baseUrl) + "activation/serviceid"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(pointRequest),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        retorno = data
      })

    return retorno;
  }

  //##############################################################################################################
  // FUNCTION: ACTIVATION 
  // Details: Função que ativa o usuário na rede
  //##############################################################################################################
  static AddElementConfiguration = async (params) => {

    var retorno;

    var pointRequest = {

      ElementId: params.elementId,
      ElementEspecificationId: params.elementEspecificationId,
      Description: params.description,
      Latitude: params.lat.toString().replace(",", "."),
      Longitude: params.lng.toString().replace(",", "."),
      Cep: params?.cep?.replace("-", ""),
      Numero: params.numero,
      Bairro: params.bairro,
      Logradouro: params.logradouro,
      Cidade: params.cidade,
      Estado: params.estado
    }

    

    await fetch(encodeURI(new URL(baseUrl) + "infra/add-element-configuration"), {
      //await fetch(encodeURI(new URL(baseUrl) + "activation/serviceid"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(pointRequest),
    })
      .then((response) => {
        
        return response.json();
      })
      .then((data) => {
        retorno = data
      })

    return retorno;
  }



  //##############################################################################################################
  // FUNCTION: ACTIVATION 
  // Details: Função que ativa o usuário na rede
  //##############################################################################################################
  static AddOltSlotsGEO = async (oltId, slots) => {

    var retorno;
    

    // var pointRequest = {
    //   Id: params.elementId,
    //   Tipo: "64F",
    //   Lat: params.lat.toString().replace(",", "."),
    //   Lng: params.lng.toString().replace(",", "."),
    //   Status: 0,
    // }


    

    await fetch(encodeURI(new URL(baseUrl) + "grafo/olt/" + oltId + "/slots"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(slots),
    })
      .then((response) => {
        
        return response.json();
      })
      .then((data) => {
        retorno = data
      })

    return retorno;
  }




  //##############################################################################################################
  // FUNCTION: Função que faz o GET das CTOS cadastradas no CF
  // Details: 
  //##############################################################################################################
  static GetCtoByCoordenate = async (lista) => {

    var request = {
      lista
    }
    //GET
    var retorno;

    await fetch(encodeURI(new URL(baseUrl) + "infra/ctoByCoordenate"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(request),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        retorno = data
      })

    return retorno;
  }

  //##############################################################################################################
  // FUNCTION: Função que faz o GET das CTOS cadastradas no CF
  // Details: 
  //##############################################################################################################
  static getAllElementsByCordinate = async (plat, plng) => {

    

    var lat = plat.toString().replace(",", ".")
    var lng = plng.toString().replace(",", ".")

    //GET
    var retorno;

    await fetch(encodeURI(new URL(baseUrl) + "infra/getAllElementsByCordinate?lat=" + lat + "&lng=" + lng), {
      mode: 'cors',
      credentials: 'omit',
      method: 'POGETST',
    })
      .then((response) => {
        
        return response.json();
      })
      .then((data) => {
        retorno = data
      })

    return retorno;
  }

  static GetProjectArea = async () => {

    // var request = {
    //   lista
    // }
    //   //GET
    //   var retorno;

    //   await fetch(encodeURI(new URL(baseUrl) + "infra/ctoByCoordenate"), {
    //     mode: 'cors',
    //     credentials: 'omit',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     method: 'POST',
    //     body: JSON.stringify(request),
    //   })
    //     .then((response) => {
    //       return response.json();
    //     })
    //     .then((data) => {
    //       retorno = data
    //     })

    var area = []

    var polygon = {
      lat: "-46.569495574806034",
      lng: "-23.61871177971456",
    }
    area.push(polygon)

    var polygon2 = {
      lat: "-46.571024661949224",
      lng: "-23.622283995971713",
    }
    area.push(polygon2)


    return area;
  }


    //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static GetAllPoints = async () => {

    //GET
    //"https://authlayerapi.azurewebsites.net
    await fetch(encodeURI(UrlBase + "authlayer/v1/grafo/getallpoints"), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoId': 'teste',
      },
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.resourceList = data
      })

    return this.resourceList;
  }


    //##############################################################################################################
  // FUNCTION: 
  // Details: Função qwue busca os servicesIds cadastrados com base no seu status
  //##############################################################################################################
  static GetPointsByCoordinate = async (lat, lng) => {

    //GET
    //"https://authlayerapi.azurewebsites.net
    await fetch(encodeURI(UrlBase + "authlayer/v1/grafo/getpointscoordinate/"+lat+"/"+lng), {
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'VnoId': 'teste',
      },
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.resourceList = data
      })

    return this.resourceList;
  }

  



}
